import React, { Component } from 'react';
import { Link } from "gatsby";
import "../style/cool-footer.css"
import Footlottie from "./lottie/footerlottie"
import Footlogo from "../images/mainlogo--white.svg";


class Coolfooter extends Component {
    render() {
        return (
            <div>
              <Footlottie />
<section className="footer">
  <div className="container">
		<div className="columns is-marginless">
		<div className="column is-4">
			<img src={Footlogo} />
		</div>

		<div className="column is-3 is-offset-3">
			<h3>Contact</h3>
			<ul>
				<li><strong>Email: </strong>derek.oherlihy@coolharbor.ie</li>
				<li><strong>Tel: </strong>00353 85 7294944</li>
			</ul>
		</div>
				<div className="column is-3">
			<h3>Links</h3>
			<ul>
				<li><Link className="navbar-item" to="/liveview/">LiveView</Link></li>
				<li><Link className="navbar-item" to="https://packlims.com/">PackLIMS</Link></li>
				<li><Link className="navbar-item" to="/about-coolharbour/">About</Link></li>
				<li><Link className="navbar-item" to="/contact/">Contact</Link></li>
			</ul>
		</div>

      </div>
  </div>
</section> 
            </div>
        );
    }
}

export default Coolfooter;