import React, { Component } from 'react';

class Contactform extends Component {
    render() {
        return (
            <div>
                <section className="sectionpadding section-d" id="contactform">
  <div className="container">
    <div className="columns is-marginless">
          <div className="column is-5 gentext">
      <p className="beforetitle">Got a project?</p>
<h3>Get in touch today</h3><hr/>
<p>Cool Harbour would love to speak with you about your next project. Please consider your proposed lead time and include as much detail as possible about your requirements.</p>
    </div>
      <div className="column is-6 is-offset-1 hotformspree">
        <form
        action="https://formspree.io/myydqrav"
        method="POST"
        className="hotform"
        >
        <div className="field">
          <div className="control">
            <input className="input is-primary" type="email" name="_replyto" placeholder="Your email"/>
          </div>
        </div>
        <div className="field">
          <div className="control">
            <textarea className="textarea" name="message"></textarea>
          </div>
        </div>
        <button className="button" type="submit">Send</button>
      </form>
    </div>
  </div>
</div>
</section>
            </div>
        );
    }
}

export default Contactform;